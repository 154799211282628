import React, { Component } from 'react'

export default class WistiaContainer extends Component {
  constructor(props) {
    super(props)
    this.state = {
      wistiaId: this.props.wistiaId,
    }
  }

  componentDidMount() {
    this.setState({
      content: this.state.content,
    })
    this.handleScriptLoad()
  }
  
  handleScriptLoad = () => {
    let allsuspects=document.getElementsByTagName("script");
    for (let i=allsuspects.length; i>=0; i--){
    if (allsuspects[i] && allsuspects[i].getAttribute("id")!==null 
      && allsuspects[i].getAttribute("id").indexOf("uei-wistia") !== -1 ){
             allsuspects[i].parentNode.removeChild(allsuspects[i])
        }    
    }

    
    var script = document.createElement("script");
    script.type = "text/javascript";
    script.src = "https://fast.wistia.com/assets/external/channel.js";
    script.setAttribute("id", "uei-wistia");
    script.async = true; 
    document.getElementsByTagName('head').item(0).appendChild(script);
  }

  render() {
    const blog = this.props.content
    const mode = 'all'
    var tags = ''
    return (
      <>
        <link rel="stylesheet" href={`https://fast.wistia.com/embed/channel/project/${this.props.wistiaId}/font.css`} /> 
        <div id="wc1" className={`wistia_channel wistia_async_${this.props.wistiaId} mode=inline`} style={{minHeight: "100vh", position: "relative", width: "100%"}}></div>
      </>
    )
  }
}